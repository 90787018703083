// @ts-nocheck
import * as ENV from 'src/env.json';
import { ProfileResponse } from 'src/lib/services/api/profile-api';
import { ValueOfObject } from 'src/types/generics';
import { joinExistingValues } from 'src/helpers/joinExistingValues';

const { INTERCOM_APP_ID } = ENV;

export const event = {
  customerRegistered: 'customer_registered',
  subscriptionCancelled: 'subscription_cancelled',
  customerSubscribed: 'customer_subscribed',
  requestSubmitted: 'request_submitted',
  requestDraftCreated: 'request_draft_created',
} as const;

type IntercomEventType = ValueOfObject<typeof event>;

window.intercomSettings = {
  app_id: '',
};

window.Intercom = undefined;

export const load = () => {
  if (!window) {
    console.error('window is not defined');
    return;
  }

  window.intercomSettings.app_id = INTERCOM_APP_ID;

  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

export const boot = (options = {}) => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }

  window.intercomSettings.app_id = INTERCOM_APP_ID;
  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    ...options,
  });
};

export const shutdown = () => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }

  window.Intercom('shutdown');
  window.intercomSettings.app_id = '';
};

export const hardShutdown = () => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }

  window.Intercom('shutdown');
  window.Intercom = undefined;
  window.intercomSettings.app_id = '';
};

export const trackEvent = (event: IntercomEventType, options = {}) => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }

  window.Intercom('trackEvent', event, options);
};

export const bootProfile = (data?: ProfileResponse) => {
  if (!data) {
    console.warn('Profile is not loaded');
    return;
  }

  boot({
    user_id: data.id,
    name: joinExistingValues([data.first_name, data.last_name], ' '),
    email: data.email,
    user_hash: data.intercom_hmac,
    type: data.type,
    days_since_last_login: data.days_since_last_login,
    created_at: data.created_at,
    submitted_requests: data.company.meta.submitted_requests,
    latest_assigned_editor:
      data.company.meta?.latest_assigned_editor?.first_name ??
      data.company.meta?.latest_assigned_editor?.email ??
      null,
    all_assigned_editors: data.company.meta.all_assigned_editors
      .map((editor) => editor?.first_name ?? editor.email)
      .join(','),
    has_creative_director_courses: data.company.meta.has_pro_plan,

    company: {
      id: data.company.id,
      name: data.company.name,
      monthly_spend: data.company.monthly_spend,
      all_subscriptions_count: data.company.meta.all_subscriptions_count,
      active_subscriptions_count: data.company.meta.active_subscriptions_count,
    },
  });
};

export const trackCustomerRegisteredEvent = () => {
  trackEvent(event.customerRegistered);
};

export const trackSubscriptionCancelledEvent = (options = {}) => {
  trackEvent(event.subscriptionCancelled, options);
};

export const trackCustomerSubscribedEvent = () => {
  trackEvent(event.customerSubscribed);
};

declare global {
  interface Window {
    intercomSettings: {
      app_id: string;
    };

    Intercom?: (...args: any) => any;
  }
}
