import { create } from 'zustand';
import { MediaObject } from 'src/api/services/MediaClient';
import { HookFormMediaObject } from 'src/components/Form/S3UploadInput';
import {
  VideoTemplateExampleResponse,
  VideoTemplateResponse,
} from 'src/lib/services/api/video-templates-api';

export type Options = {
  isMultiStep?: boolean;
  currentStep?: number;
  selectedTemplate?: VideoTemplateResponse & {
    exampleTemplate?: VideoTemplateExampleResponse['footage'];
  };
  // TODO: temporary type, remove after request form refactor
  isRepurpose?: boolean;
};

interface RequestFormStore {
  modalState: number;
  isModalOpen: boolean;
  isMinimized?: boolean;
  requestName?: string;
  uploadingFiles: MediaObject[] | HookFormMediaObject[];
  selectedTemplate?: VideoTemplateResponse & {
    exampleTemplate?: VideoTemplateExampleResponse['footage'];
  };
  currentRequestId?: string;
  isMultiStep?: boolean;
  currentStep?: number;
  // TODO: temp prop until request refactor
  isRepurpose?: boolean;

  setRequestName: (requestName?: string) => void;
  setUploadingFiles: (files?: MediaObject[] | HookFormMediaObject[]) => void;
  setCurrentRequestId: (id?: string) => void;
  setCurrentStep: (step: number) => void;
  setSelectedTemplate: (
    template:
      | (VideoTemplateResponse & { exampleTemplate?: VideoTemplateExampleResponse['footage'] })
      | undefined,
  ) => void;

  openModal: (options?: Options) => void;
  minimizeModal: (options?: Options) => void;
  discardModal: () => void;
  reset: () => void;
}

const defaultValues = {
  isMinimized: undefined,
  requestName: undefined,
  uploadingFiles: [],
  currentRequestId: undefined,
  isMultiStep: undefined,
  currentStep: undefined,
  selectedTemplate: undefined,
  // TODO: temp prop until request refactor
  isRepurpose: undefined,
};

const useRequestFormStore = create<RequestFormStore>((set) => ({
  modalState: 0,
  isModalOpen: false,
  isMinimized: defaultValues.isMinimized,
  requestName: defaultValues.requestName,
  uploadingFiles: defaultValues.uploadingFiles,
  currentRequestId: defaultValues.currentRequestId,
  isMultiStep: defaultValues.isMultiStep,
  currentStep: defaultValues.currentStep,
  selectedTemplate: defaultValues.selectedTemplate,
  // TODO: temp prop until request refactor
  isRepurpose: defaultValues.isRepurpose,

  setRequestName: (requestName) => set(() => ({ requestName })),
  setUploadingFiles: (uploadingFiles) => set(() => ({ uploadingFiles })),
  setCurrentRequestId: (id) => set(() => ({ currentRequestId: id })),
  setCurrentStep: (step) => set(() => ({ currentStep: step })),
  setSelectedTemplate: (template) => set(() => ({ selectedTemplate: template })),

  openModal: (options) => set(() => ({ isModalOpen: true, isMinimized: false, ...options })),
  minimizeModal: (options) => set(() => ({ isModalOpen: false, isMinimized: true, ...options })),
  discardModal: () =>
    set(() => ({
      isModalOpen: false,
      isMinimized: false,
      isMultiStep: undefined,
      currentStep: undefined,
      selectedTemplate: undefined,
      // TODO: temp prop until request refactor
      isRepurpose: undefined,
    })),
  reset: () =>
    set((state) => ({ ...defaultValues, isModalOpen: false, modalState: state.modalState + 1 })),
}));

export { useRequestFormStore };
