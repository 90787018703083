import { ValueOfObject } from 'src/types/generics';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface GTMProductObject {
  item_id: string;
  item_name: string;
  currency: 'USD' | string;
  price: number;
  quantity: number;
  discount?: number;
  coupon?: string;
}

interface GTMUserPropertiesObject {
  id: string;
  email: string;
}

interface GTMPurchaseEventObject {
  transaction_id: string;
  value: number;
  total_price: number;
  total_recurring_price: number;
  currency: string;
  billing_interval: string;
  items: GTMProductObject[];
  user_properties: GTMUserPropertiesObject;
  coupon?: string;
}

interface GTMEcommerceObject {
  transaction_id: string;
  value: number;
}

export const GoogleTagManagerEvent = {
  purchase: 'purchase',
  login: 'login',
  signUp: 'sign_up',
  viewItem: 'view_item',
  addToCart: 'add_to_cart',
  beginCheckout: 'begin_checkout',
  addPaymentInfo: 'add_payment_info',
} as const;

export type GoogleTagManagerEventType = ValueOfObject<typeof GoogleTagManagerEvent>;

export const GoogleTagManagerAuthenticateEventMethod = {
  google: 'google',
  email: 'email',
  facebook: 'facebook',
} as const;

export type GoogleTagManagerEventDataType = {
  login: {
    method: ValueOfObject<typeof GoogleTagManagerAuthenticateEventMethod>;
  } & Record<string, any>;
  sign_up: {
    method: ValueOfObject<typeof GoogleTagManagerAuthenticateEventMethod>;
  } & Record<string, any>;
  view_item: {
    ecommerce: {
      currency: 'USD' | string;
      value: number;
      items: GTMProductObject[];
    };
  } & Record<string, any>;
  add_to_cart: {
    ecommerce: {
      currency: 'USD' | string;
      value: number;
      items: GTMProductObject[];
    };
  } & Record<string, any>;
  begin_checkout: {
    ecommerce: {
      currency: 'USD' | string;
      value: number;
      items: GTMProductObject[];
      coupon?: string;
    };
  } & Record<string, any>;
  add_payment_info: {
    ecommerce: {
      currency: 'USD' | string;
      value: number;
      items: GTMProductObject[];
      coupon?: string;
      payment_type?: string;
    };
  } & Record<string, any>;
  purchase: GTMPurchaseEventObject;
};

export type { GTMPurchaseEventObject, GTMProductObject, GTMEcommerceObject };
