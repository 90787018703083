import React, { useState } from 'react';
import { If } from 'src/components/If';
import { t } from 'i18next';
import { useMakeInfiniteQuery } from 'src/api/queries/useMakeInfiniteQuery';
import { PaginatedResponse } from 'src/api/Client';
import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { Droppable } from '@hello-pangea/dnd';
import {
  ApiQueryObject,
  DEFAULT_PAGE_SIZE,
  useApiQueryParams,
} from 'src/lib/services/api-query-params';
import { cn } from 'src/lib/utils';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { SpinnerLoader } from 'src/components/ui/loader';
import { RequestKanbanColumnList } from 'src/features/requests/request-kanban/request-kanban-column-list';
import { NoRecordsFallback } from 'src/features/fallback/ui/no-records-fallback';
import { RequestKanbanCardProps } from 'src/features/requests/request-kanban/request-kanban-card';
import { scopedQueries } from 'src/api/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestClient, RequestsTableResponse, RequestStatus } from 'src/lib/services/api/request-api';
import { Loader2 } from 'lucide-react';
import { requestStatusTranslationsMap } from 'src/features/requests/request.helper';

type RequestKanbanColumnRef = {
  swapCards: (sourceItemIndex: number, targetItemIndex: number) => void;
};

type Props = {
  status: RequestStatus;
  isDropDisabled?: boolean;
  queryKey: readonly any[];
  queryFn: (
    filters: ApiQueryObject,
    context: QueryFunctionContext<readonly any[]>,
  ) => Promise<PaginatedResponse<RequestsTableResponse>>;
  cardProps?: RequestKanbanCardProps['cardProps'];
  pageSize?: number;
};

const RequestKanbanColumn = React.forwardRef<RequestKanbanColumnRef, Props>(
  ({ queryKey, queryFn, status, isDropDisabled, cardProps, pageSize = DEFAULT_PAGE_SIZE }, ref) => {
    // Used to determine if dragging is happening
    const [isDragging, setIsDragging] = useState(false);
    // Used to trigger memoized list re-render
    const [renderCount, setRenderCount] = useState(0);
    const { filters } = useApiQueryParams();
    const queryClient = useQueryClient();

    const localQueryKey = [...queryKey, status, scopedQueries.infinite, filters];

    const isDragDisabled = !!filters.sorts?.length;

    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } =
      useMakeInfiniteQuery<PaginatedResponse<RequestsTableResponse>>(
        {
          queryKey: [...queryKey, status],
          queryFn: (context) => queryFn(filters, context),
          getNextPageParam: (lastPage) =>
            lastPage.meta.current_page < lastPage.meta.last_page
              ? lastPage.meta.current_page // current_page comes as a number of the next page from backend
              : undefined,
          staleTime: 60 * 1000,
        },
        filters,
      );

    const reorderMutation = useMutation({
      mutationFn: ([sourceItemIndex, targetItemIndex, sourceRequestId, targetRequestId]: [
        number,
        number,
        string,
        string,
      ]) => {
        return requestClient.sort(sourceRequestId, {
          request_id: targetRequestId,
          position: sourceItemIndex > targetItemIndex ? 'before' : 'after',
        });
      },
      onMutate: ([sourceItemIndex, targetItemIndex]) => {
        queryClient.setQueryData(localQueryKey, (prev: any) => {
          const newSourcePages = [...prev.pages];

          // flatten page items
          const allItems = newSourcePages.reduce(
            (items: any[], page: any) => items.concat(page.items),
            [],
          );

          // move items
          const [movedItem] = allItems.splice(sourceItemIndex, 1);
          allItems.splice(targetItemIndex, 0, movedItem);

          // map flattened items list back into pages
          newSourcePages.forEach((page: any, pageIndex: number) => {
            page.items = allItems.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
          });

          return { pages: newSourcePages, pageParams: prev.pageParams };
        });

        // trigger list re-render
        setRenderCount((prev) => prev + 1);
      },
      onSettled: () => {
        queryClient.invalidateQueries(localQueryKey);
      },
    });

    const totalRecords = data?.pages[0].meta.total || 0;

    const onScrollToBottom = async (ref: HTMLDivElement) => {
      const reachedBottom = ref.scrollTop + ref.clientHeight >= ref.scrollHeight;

      if (!reachedBottom || isDragging || !hasNextPage) {
        return;
      }

      await fetchNextPage();
    };

    // expose swapCards function to parent component via column component ref
    React.useImperativeHandle(
      ref,
      () => {
        return {
          swapCards(sourceItemIndex: number, targetItemIndex: number) {
            // we need to get the source and target request ids before running the mutation,
            // in order to get the correct items and be able to perform an optimistic update
            const sourcePageIndex = Math.floor(sourceItemIndex / pageSize);
            const targetPageIndex = Math.floor(targetItemIndex / pageSize);
            const sourceIndex = sourceItemIndex % pageSize;
            const targetIndex = targetItemIndex % pageSize;

            const requests: any = queryClient.getQueryData(localQueryKey);
            const sourceRequestId = requests.pages[sourcePageIndex].items[sourceIndex].id;
            const targetRequestId = requests.pages[targetPageIndex].items[targetIndex].id;

            reorderMutation.mutate([
              sourceItemIndex,
              targetItemIndex,
              sourceRequestId,
              targetRequestId,
            ]);
          },
        };
      },
      [filters, pageSize],
    );

    return (
      <div className={'tw-flex tw-min-w-[240px] tw-flex-1'}>
        <div
          className={cn(
            'tw-flex tw-grow tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-border-border-subtle tw-bg-background tw-pe-1 tw-transition-colors',
            isDragging && 'tw-border-brand',
            reorderMutation.isLoading && 'tw-relative',
          )}
        >
          {/*{reorderMutation.isLoading && (*/}
          {/*  <div*/}
          {/*    className={*/}
          {/*      'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-animate-pulse tw-items-center tw-rounded-lg tw-bg-neutral-200/70'*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Loader2 className={'tw-mx-auto tw-size-12 tw-animate-spin tw-text-brand'} />*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className={'tw-px-6 tw-pt-6'}>
            <span className={'tw-text-title-sm tw-font-semibold tw-text-text-strong'}>
              {t(requestStatusTranslationsMap(status))}
            </span>
            <span className="tw-ms-3 tw-text-sm tw-lowercase tw-text-text-inactive">
              {totalRecords}&nbsp;
              {t('models/request:name.plural')}
            </span>
          </div>
          <ScrollArea onScrollDown={(ref) => onScrollToBottom(ref)}>
            <div
              className={
                'tw-h-min tw-w-0 tw-min-w-full tw-pb-4 tw-pe-3 tw-ps-4 md:tw-pe-5 md:tw-ps-6'
              }
            >
              <If
                when={!isLoading}
                else={
                  <div className={'tw-flex tw-justify-center tw-py-8'}>
                    <SpinnerLoader loading={true} className={'tw-h-9 tw-w-9'} />
                  </div>
                }
              >
                <If
                  when={!!totalRecords}
                  else={
                    <NoRecordsFallback
                      className={'tw-rounded-lg tw-border tw-bg-neutral-50 tw-p-4'}
                    />
                  }
                >
                  <Droppable
                    key={`droppable-${status}`}
                    droppableId={status}
                    type={status}
                    isDropDisabled={isDropDisabled}
                  >
                    {(provided, snapshot) => {
                      const hasStartedDragging = !!snapshot.draggingFromThisWith;

                      if (hasStartedDragging !== isDragging) {
                        setIsDragging(hasStartedDragging);
                      }

                      return (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          <RequestKanbanColumnList
                            pages={data?.pages || []}
                            status={status}
                            cardProps={cardProps}
                            isDropDisabled={isDropDisabled}
                            isDragDisabled={isDragDisabled}
                            pageSize={pageSize}
                            renderCount={renderCount}
                          />

                          {provided.placeholder}

                          {isFetchingNextPage && (
                            <div className={'tw-flex tw-justify-center tw-py-3'}>
                              <SpinnerLoader loading={true} className={'tw-h-9 tw-w-9'} />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </Droppable>
                </If>
              </If>
            </div>
          </ScrollArea>
        </div>
      </div>
    );
  },
);
RequestKanbanColumn.displayName = 'RequestKanbanColumn';

export { RequestKanbanColumn, type RequestKanbanColumnRef };
