import { ValueOfObject } from 'src/types/generics';

const hubspotEvents = {
  SubscriptionCancelled: 'subscription_cancelled',
  CustomerSubscribed: 'customer_subscribed',
  RequestSubmitted: 'request_submitted',
  RequestDraftCreated: 'request_draft_created',
  CustomerTriedToReportABug: 'customer_tried_to_report_a_bug',
  CustomerRegistered: 'customer_registered',
  Identify: 'identify',
  TrackPageView: 'trackPageView',
  SetPath: 'setPath',
} as const;

type HubspotEvents = ValueOfObject<typeof hubspotEvents>;

export { hubspotEvents, type HubspotEvents };
