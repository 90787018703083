import React, { useState } from 'react';
import { requestOutcomeStatus } from 'src/lib/services/api/request-api';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import { Badge } from 'src/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { requestStatusTranslationsMap } from 'src/features/requests/request.helper';

const RequestArchivedOutcomesContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className={'tw-pb-4'}>
      <CollapsibleTrigger asChild className={'tw-group tw-pb-4'}>
        <div className="tw-mx-4 tw-flex tw-items-center tw-justify-center">
          <div className="tw-grow tw-border-t tw-border-neutral-300" />
          <Badge
            variant="unset"
            className="tw-gap-2 tw-bg-neutral-300 tw-text-base tw-font-medium group-hover:tw-bg-neutral-400/30"
          >
            {t(requestStatusTranslationsMap(requestOutcomeStatus.archived))}
            <ChevronDown
              size={16}
              className={cn('tw-transition-transform', {
                'tw-rotate-180': isOpen,
              })}
            />
          </Badge>
          <div className="tw-grow tw-border-t tw-border-neutral-300" />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent ref={ref} {...props}>
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
});
RequestArchivedOutcomesContainer.displayName = 'RequestArchivedOutcomesContainer';

export { RequestArchivedOutcomesContainer };
