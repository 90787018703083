import TagManager from 'react-gtm-module';
import {
  GoogleTagManagerEvent,
  GTMEcommerceObject,
  GTMPurchaseEventObject,
  GoogleTagManagerEventDataType,
  GoogleTagManagerEventType,
  GoogleTagManagerAuthenticateEventMethod,
} from './GoogleTagManager.types';

import { GTM_APP_ID } from 'src/env.json';
import { system } from 'src/lib/utils';
import { customTagManager } from 'src/services/GoogleTagManager/custom-tag-manager';

class GoogleTagManager {
  public static init = () => {
    if (system.env.isProduction() && customTagManager.validateArgs()) {
      customTagManager.loadscript();
      customTagManager.loadnoscript();
    } else {
      TagManager.initialize({
        gtmId: GTM_APP_ID,
      });
    }
  };

  public static event: (
    e: GoogleTagManagerEventType,
    data: Record<string, any>,
    options?: Record<string, any>,
  ) => void = (event, data) => {
    this.push({
      event,
      ...data,
    });
  };

  public static purchase = (modelData: GTMPurchaseEventObject, ecommerce: GTMEcommerceObject) => {
    this.event(GoogleTagManagerEvent.purchase, {
      eventModel: modelData,
      ecommerce,
    });
  };

  public static login = (data?: GoogleTagManagerEventDataType['login']) => {
    this.event(GoogleTagManagerEvent.login, {
      ...data,
    });
  };

  public static signUp = (data?: GoogleTagManagerEventDataType['sign_up']) => {
    this.event(GoogleTagManagerEvent.signUp, { ...data });
  };

  public static signUpWithEmail = (data?: Record<string, any>) => {
    this.signUp({ ...data, method: GoogleTagManagerAuthenticateEventMethod.email });
  };

  public static loginWithEmail = (data?: Record<string, any>) => {
    this.login({ ...data, method: GoogleTagManagerAuthenticateEventMethod.email });
  };

  public static viewItem = (data: GoogleTagManagerEventDataType['view_item']) => {
    this.event(GoogleTagManagerEvent.viewItem, data);
  };

  public static addToCart = (data: GoogleTagManagerEventDataType['add_to_cart']) => {
    this.event(GoogleTagManagerEvent.addToCart, data);
  };

  public static beginCheckout = (data: GoogleTagManagerEventDataType['begin_checkout']) => {
    this.event(GoogleTagManagerEvent.beginCheckout, data);
  };

  public static addPaymentInfo = (data: GoogleTagManagerEventDataType['add_payment_info']) => {
    this.event(GoogleTagManagerEvent.addPaymentInfo, data);
  };

  private static push = (obj: Record<string, any>) => {
    window.dataLayer?.push(obj);
  };
}

export { GoogleTagManager };
