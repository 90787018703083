import { HUBSPOT_HUB_ID } from 'src/env.json';
import { hubspotEvents } from './hubspot.types';

declare global {
  interface Window {
    _hsq?: any[];
    HubSpotConversations: {
      widget: {
        open: (options?: { widgetOpen: boolean }) => void;
        close: () => void;
        load: () => void;
        remove: () => void;
        status: (options?: { loaded: boolean } ) => void;
        refresh: (options?: { openToNewThread: boolean }) => void;
      };
    };
  }
}

export const isLoaded = () =>
  window?._hsq && typeof window._hsq === 'object' && 'push' in window._hsq;

export const load = async () => {
  if (isLoaded() || !HUBSPOT_HUB_ID) {
    return window._hsq;
  }

  // Before loading hs, fake existence of form collection script to disable non-HubSpot forms
  // https://community.hubspot.com/t5/Blog-Website-Page-Publishing/Prevent-Cookie-tracking-of-a-specific-form/m-p/527851
  const fakeFormCollectionScript = document.createElement('script');
  fakeFormCollectionScript.id = `CollectedForms-${HUBSPOT_HUB_ID}`;
  fakeFormCollectionScript.type = 'text/javascript';
  document.head.append(fakeFormCollectionScript);

  const script = document.createElement('script');

  script.id = 'hs-script-loader';
  script.type = 'text/javascript';
  script.defer = true;
  script.src = `https://js-eu1.hs-scripts.com/${HUBSPOT_HUB_ID}.js`;

  return new Promise<typeof window._hsq>((resolve, reject) => {
    script.onload = () => {
      resolve(window._hsq);
    };

    script.onerror = (err) => {
      reject(err);
    };

    document.head.append(script);
  });
};

export const push = (args: any[]) => {
  window._hsq?.push(...args);
};

export const identify = (email: string) => {
  push([hubspotEvents.Identify, { email }]);
};

export const trackPageView = () => {
  push([hubspotEvents.TrackPageView]);
};

export const setPathAndTrackPageView = (path: string) => {
  push([hubspotEvents.SetPath, path]);
  push([hubspotEvents.TrackPageView]);
};
